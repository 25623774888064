// import moment from "moment-timezone";
import Crop32OutlinedIcon from "@mui/icons-material/Crop32Outlined";
import CropPortraitOutlinedIcon from "@mui/icons-material/CropPortraitOutlined";
import CropSquareOutlinedIcon from "@mui/icons-material/CropSquareOutlined";
import SpecialDayImg from "../assets/image/special-day.png";
import { getStatusColor } from "../components/ContentCalendar/CalenderHelpers/ExtraData";
import BrazilFlagIcon from "../components/common/MuiSvgIcons/BrazilFlagIcon";
import ChineseFlagIcon from "../components/common/MuiSvgIcons/ChineseFlagIcon";
import CrotiaFlagIcon from "../components/common/MuiSvgIcons/CrotiaFlagIcon";
import CzechFlagIcon from "../components/common/MuiSvgIcons/CzechFlagIcon";
import DenmarkFlag2Icon from "../components/common/MuiSvgIcons/Denmark2FlagIcon";
import DenmarkFlagIcon from "../components/common/MuiSvgIcons/DenmarkFlagIcon";
import FinlandFlagIcon from "../components/common/MuiSvgIcons/FinlandFlagIcon";
import FrenchFlagIcon from "../components/common/MuiSvgIcons/FrenchFlagIcon";
import HungaryFlagIcon from "../components/common/MuiSvgIcons/HungaryFlagIcon";
import IndonesiaFlagIcon from "../components/common/MuiSvgIcons/IndonesiaFlagIcon";
import ItalyFlagIcon from "../components/common/MuiSvgIcons/ItalyFlagIcon";
import MalaysiaFlagIcon from "../components/common/MuiSvgIcons/MalaysiaFlagIcon";
import NetherlandFlagIcon from "../components/common/MuiSvgIcons/NetherlandFlagIcon";
import NorwayFlagIcon from "../components/common/MuiSvgIcons/NorwayFlagIcon";
import PolandFlagIcon from "../components/common/MuiSvgIcons/PolandFlagIcon";
import PortugeseFlagIcon from "../components/common/MuiSvgIcons/PortugeseFlagIcon";
import RomaniaFlagIcon from "../components/common/MuiSvgIcons/RomaniaFlagIcon";
import SpainFlagIcon from "../components/common/MuiSvgIcons/SpainFlagIcon";
import SwedenFlagIcon from "../components/common/MuiSvgIcons/SwedenFlagIcon";
import TurkeyFlagIcon from "../components/common/MuiSvgIcons/TurkeyFlagIcon";
import UKFlagIcon from "../components/common/MuiSvgIcons/UKFlagIcon";
import USFlagIcon from "../components/common/MuiSvgIcons/USFlagIcon";
import VietnamFlagIcon from "../components/common/MuiSvgIcons/VietnamFlagIcon";
import WorldFlagIcon from "../components/common/MuiSvgIcons/WorldFlagIcon";

export const GET_HEATMAP_CHART_END_POINT = "get_heatmap_chart_data";
export const GET_BAR_CHART_END_POINT = "get_bar_chart_data";
export const GET_PIE_CHART_END_POINT = "get_pie_chart_data";
export const GET_HEATMAP_MEDIATYPE_CHART_END_POINT = "get_post_perf_chart_data";
export const GET_HASHTAGS_DATA = "get_hashtag_chart_data";

export const GET_SUNBURST_END_POINT = "get_sunburst_chart_data";
export const GET_CONTENTTHEME_END_POINT = "get_clustering_data";
export const GET_BASICSTATS_END_POINT = "get_basic_stats_data";
export const GET_SAVED_ANALYSES = "getSavedAnalysis";
export const infoColors = (theme: any) => [
  {
    hex: getStatusColor("scheduled", theme),
    txt: "commonTexts.scheduled",
    type: "scheduled"
  },
  // {
  //   hex: getStatusColor("placed", theme),
  //   txt: "contentCalender.topBtn.infoColors.text2"
  // },
  {
    hex: getStatusColor("failed", theme),
    txt: "contentCalender.topBtn.infoColors.text3",
    type: "failed"
  },
  {
    hex: getStatusColor("published", theme),
    txt: "commonTexts.commonBtn.publishText",
    type: "published"
  },
  {
    hex: getStatusColor("rejected", theme),
    txt: "contentCalender.topBtn.infoColors.text5",
    type: "rejected"
  },
  {
    hex: getStatusColor("in_review", theme),
    txt: "contentCalender.topBtn.infoColors.text4",
    type: "in_review"
  }
];
export const PLATFORM_MAX_CAROUSEL_PAGES = {
  ig: 10,
  igs: 1,
  fb: 1000,
  fbr: 1,
  li: 100,
  pi: 5,
  gmb: 1,
  tt: 35,
  fbg: 1000,
  twt: 4,
  yt: 5
};

export const PLATFORM_VIDEO_DURATION_MAX = {
  ig: 15 * 60,
  igs: 60,
  fb: 240 * 60,
  fbr: 90,
  fbg: 240 * 60,
  li: 10 * 60,
  gmb: 0,
  tt: 1 * 60,
  pi: 15 * 60,
  twt: 140,
  yt: 12 * 60 * 60
};

export const PLATFORM_MAX_HASHTAGS = {
  ig: 30,
  igs: 99999,
  fb: 10000,
  fbr: 10000,
  fbg: 10000,
  li: 10000,
  pi: 10000,
  gmb: 10000,
  tt: 30,
  twt: 10000,
  yt: 15
};
export const PLATFORM_MAX_MENTIONS = {
  ig: 20,
  igs: 99999,
  fb: 10000,
  fbr: 10000,
  fbg: 10000,
  li: 25,
  pi: 10000,
  gmb: 1000,
  tt: 20,
  twt: 10,
  yt: 10
};
export const PLATFORM_CAPTION_MAX_CHARS = {
  ig: 2200,
  igs: 99999,
  fb: 10000,
  fbr: 10000,
  li: 3000,
  pi: 500,
  gmb: 1500,
  fbg: 10000,
  tt: 2200,
  twt: 275,
  yt: 5000
};

export const PLATFORM_MAX_CHARS_TITLE = {
  pi: 100,
  gmb: 58,
  yt: 100,
  tt: 90
};

export const PREVIEW_UNSUPPORTED_ERROR = "media_not_supported";

export const PREVIEW_CREATIVE_ERRORS = [
  "aspect_ratio_valid",
  "n_pages_valid",
  "is_width_valid",
  "is_height_valid",
  "is_duration_valid",
  "media_url_valid"
];
export const PREVIEW_LINK_ERRORS = ["link_valid"];
export const PREVIEW_TITLE_ERRORS = ["is_title_valid"];
export const PREVIEW_CAPTION_ERRORS = ["caption_len_valid", "n_hashtags_valid", "n_mentions_valid"];
// Intl.DateTimeFormat().resolvedOptions().timeZone
// Intl .timeZone resolves to undefined on MacOS Sonoma so using new Date().getTimezoneOffset()
export const PARAM_TIMEZONE = new Date().getTimezoneOffset(); //Intl.DateTimeFormat().resolvedOptions().timeZone; //moment.tz.guess();
export const GET_POSTS_END_POINT = "get_posts_data";
export const GET_BEST_PERF_POST_END_POINT = "get_best_perf_posts";
export const GET_SB_POST_END_POINT = "get_sb_category_posts";
export const GET_SB_DATA_MEDIA_TYPE_END_POINT = "get_sb_data_media_type";
export const GET_DASHBOARD_PERFORMANCE = "get_post_perf_dsb";
export const RZP_TEST_KEY = "rzp_test_87wGOhUCaGGVGj";
export const TRIAL_PLAN = "cbdemo_trial";
export const FREE_PLAN = "forever_free";
export const SEMRUSH_PLAN = "semrush_base_plan";
export const LTD_PLANS_ARRAY = ["pg_49_ltd", "pg_99_ltd", "pg_199_ltd", "pg_295_ltd", "as_59_ltd"];
export const PAID_PLANS_ARRAY = [
  "pg_49_ltd",
  "pg_99_ltd",
  "pg_199_ltd",
  "pg_295_ltd",
  "as_59_ltd",
  "solo",
  "solo_inr",
  "solo_ann",
  "solo_ann_inr",
  "growth",
  "growth_inr",
  "growth_ann",
  "growth_ann_inr",
  "starter",
  "starter_inr",
  "starter_ann",
  "starter_ann_inr",
  "agency",
  "agency_inr",
  "agency_ann",
  "agency_ann_inr"
];
export const SETTINGS_ACCOUNTS_INCREMENT_VALUE = 1;
export const TOOLTIP_SHADOW = "0px 0px 14px 0px rgba(166,166,166,1)";
export const TOOLTIP_STYLE = {
  boxShadow: TOOLTIP_SHADOW,
  padding: "1rem",
  transition: "0.1s all ease-in",
  background: "#ffffff",
  color: "black"
};

export const BOX_SHADOW_VALUE = "0 1px 20px 0 rgba(0,0,0,0.1)";
export const BORDER_RADIUS_VALUE = "24px";
export const TOOLTIP_STYLE_TRANSFORM = {
  margin: 15,
  ...TOOLTIP_STYLE
};
export const TONALITY_OPTIONS = [
  "Professional",
  "Informal",
  "Friendly",
  "Enthusiastic",
  "Humorous",
  "Inspirational",
  "Persuasive",
  "Luxurious",
  "Conversational",
  "Educational",
  "Emotional",
  "Authoritative",
  "Nostalgic",
  "Romantic",
  "Playful",
  "Sarcastic",
  "Urgent",
  "Empathetic",
  "Uplifting",
  "Minimalist"
];
export const TONES = [
  {
    val: "Casual",
    label: "contentLibrary.editModal.captionTab.topBtn.tones.labels.label1"
  },
  {
    val: "Funny",
    label: "contentLibrary.editModal.captionTab.topBtn.tones.labels.label2"
  },
  {
    val: "Formal",
    label: "contentLibrary.editModal.captionTab.topBtn.tones.labels.label3"
  },
  {
    val: "Witty",
    label: "contentLibrary.editModal.captionTab.topBtn.tones.labels.label4"
  },
  {
    val: "Persuasive",
    label: "contentLibrary.editModal.captionTab.topBtn.tones.labels.label5"
  },
  {
    val: "Empathetic",
    label: "contentLibrary.editModal.captionTab.topBtn.tones.labels.label6"
  }
];
export const CAP_SIZES = [
  {
    val: "small",
    label: "contentLibrary.editModal.captionTab.topBtn.captionSize.labels.label1"
  },
  {
    val: "medium",
    label: "contentLibrary.editModal.captionTab.topBtn.captionSize.labels.label2"
  },
  {
    val: "large",
    label: "contentLibrary.editModal.captionTab.topBtn.captionSize.labels.label3"
  }
];
export const NORMAL_POST_ARR = [
  {
    title: "BUSINESS POSTS",
    desc: "Posts Suitable To Promote Your Business",
    img: "https://brain.predis.ai/templates_images/62d7fa86986fe1689cf7a796.png",
    id: "business"
  },
  {
    title: "SPECIAL DAY POSTS",
    desc: "Posts To Celebrate Holidays and Festivals",
    img: SpecialDayImg,
    id: "special"
  },
  {
    title: "QUOTES POSTS",
    desc: "Posts With Quotes By Famous People",
    img: "https://brain.predis.ai/templates_images/62de57c74edd9275936d71c2.png",
    id: "quotes"
  },
  {
    title: "E-COM POSTS",
    desc: "Posts To Promote Your E-Com Products",
    img: "https://brain.predis.ai/templates_images/627a24aa907852c8c9b1defd.png",
    id: "ecom"
  }
];
export const CAROUSEL_POST_ARR = [
  {
    title: "BUSINESS POSTS",
    desc: "Posts Suitable To Promote Your Business",
    img: "https://brain.predis.ai/templates_images/62d7fa86986fe1689cf7a796.png",
    id: "business"
  },
  {
    title: "E-COM POSTS",
    desc: "Posts To Promote Your E-Com Products",
    img: "https://brain.predis.ai/templates_images/627a24aa907852c8c9b1defd.png",
    id: "ecom"
  }
];
export const VIDEO_POST_ARR = [
  {
    title: "BUSINESS VIDEO",
    desc: "Reels/Videos Suitable To Promote Your Business",
    img: "https://brain.predis.ai/templates_images/62d7fa86986fe1689cf7a796.png",
    id: "business"
  },
  {
    title: "E-COM VIDEO",
    desc: "Reels/Videos To Promote Your E-Com Products",
    img: "https://brain.predis.ai/templates_images/627a24aa907852c8c9b1defd.png",
    id: "ecom"
  }
];
export const CREATE_POST_MAX_ASSETS_COUNT = 10;
export const EDIT_TABS_ARR = [
  {
    label: "Creatives",
    index: 0
  },
  {
    label: "Captions",
    index: 1
  },
  {
    label: "Hashtags",
    index: 2
  },
  {
    label: "Suggestions",
    index: 3
  }
];
export const SYSTEM_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:00";
export const DISCRETE_CHARTS_COLORS_1 = ["#16a085", "#2980b9", "#fa4d56", "#8e44ad", "#f39c12"];
export const DISCRETE_CHARTS_COLORS_2 = ["#08bdba", "#fa4d56", "#a56eff", "#33b1ff", "#f1c21b"];
export const SEQUENTIAL_CHARTS_COLORS_1 = ["#FFFFFF", "#009d9a"];

export const COLOR_MUTED = "#9e9e9e";
export const COLOR_SUCCESS = "#28A745";
export const COLOR_PRIMARY = "#296bd6";
export const COLOR_DANGER = "#dc3545";
export const COLOR_FACEBOOK = "#4267b2";
export const COLOR_INSTAGRAM = "#e1306c";
export const COLOR_LINKEDIN = "#0077b5";
export const LANGUAGE_LIST = [
  {
    value: "malay",
    label: "🇲🇾 Malay ",
    labelWithoutFlag: "Malay",
    nativeLabel: "Bahasa Melayu",
    icon: <MalaysiaFlagIcon fontSize='small' />
  },
  {
    value: "chinese (traditional)",
    label: "🇨🇳 中文 ",
    labelWithoutFlag: "中文",
    nativeLabel: "Chinese - Traditional",
    icon: <ChineseFlagIcon fontSize='small' />
  },
  {
    value: "chinese (simplified)",
    label: "🇨🇳 简体中文 ",
    labelWithoutFlag: "简体中文",
    nativeLabel: "Chinese - Simplified",
    icon: <ChineseFlagIcon fontSize='small' />
  },
  {
    value: "croatian",
    label: "🇭🇷 Hrvatski",
    labelWithoutFlag: "Hrvatski",
    nativeLabel: "Croatian",
    icon: <CrotiaFlagIcon fontSize='small' />
  },
  {
    value: "english",
    label: "🇺🇸 English (US)",
    labelWithoutFlag: "English (US)",
    nativeLabel: "English",
    icon: <USFlagIcon fontSize='small' />
  },
  {
    value: "dutch",
    label: "🇳🇱 Nederlands ",
    nativeLabel: "Dutch",
    labelWithoutFlag: "Nederlands",
    icon: <NetherlandFlagIcon fontSize='small' />
  },
  {
    value: "indonesian",
    label: "🇮🇩 Bahasa Indonesia ",
    labelWithoutFlag: "Bahasa Indonesia",
    nativeLabel: "Indonesian",
    icon: <IndonesiaFlagIcon fontSize='small' />
  },
  {
    value: "vietnamese",
    label: "🇻🇳 Tiếng Việt ",
    labelWithoutFlag: "Tiếng Việt",
    nativeLabel: "Vietnamese",
    icon: <VietnamFlagIcon fontSize='small' />
  },
  {
    value: "portuguese_br",
    label: "🇧🇷 Português Brasileiro",
    labelWithoutFlag: "Português Brasileiro",
    nativeLabel: " Portuguese BR",
    icon: <BrazilFlagIcon fontSize='small' />
  },
  {
    value: "finnish",
    label: "🇫🇮 Suomi",
    labelWithoutFlag: "Suomi",
    nativeLabel: "Finnish",
    icon: <FinlandFlagIcon fontSize='small' />
  },
  {
    value: "french",
    label: "🇫🇷 Français",
    nativeLabel: "French",
    labelWithoutFlag: "Français",
    icon: <FrenchFlagIcon fontSize='small' />
  },
  {
    value: "german",
    label: "🇩🇪 Deutsch",
    nativeLabel: "German",
    labelWithoutFlag: "Deutsch",
    icon: <DenmarkFlagIcon fontSize='small' />
  },
  {
    value: "italian",
    label: "🇮🇹 Italiano",
    nativeLabel: "Italian",
    labelWithoutFlag: "Italiano",
    icon: <ItalyFlagIcon fontSize='small' />
  },
  {
    value: "norwegian",
    label: "🇳🇴 Norsk",
    labelWithoutFlag: "Norsk",
    nativeLabel: "Norwegian",
    icon: <NorwayFlagIcon fontSize='small' />
  },
  {
    value: "polish",
    label: "🇵🇱 Polski",
    labelWithoutFlag: "Polski",
    nativeLabel: "Polish",
    icon: <PolandFlagIcon fontSize='small' />
  },
  {
    value: "romanian",
    label: "🇷🇴 Română",
    labelWithoutFlag: "Română",
    nativeLabel: "Romanian",
    icon: <RomaniaFlagIcon fontSize='small' />
  },
  {
    value: "czech",
    label: "🇨🇿 Čeština",
    labelWithoutFlag: "Čeština",
    nativeLabel: "Czech",
    icon: <CzechFlagIcon fontSize='small' />
  },
  {
    value: "spanish",
    label: "🇪🇸 Español",
    labelWithoutFlag: "Español",
    nativeLabel: "Spanish",
    icon: <SpainFlagIcon fontSize='small' />
  },
  {
    value: "swedish",
    label: "🇸🇪 Svenska",
    labelWithoutFlag: "Svenska",
    nativeLabel: "Swedish",
    icon: <SwedenFlagIcon fontSize='small' />
  },
  {
    value: "turkish",
    label: "🇹🇷 Türkçe",
    labelWithoutFlag: "Türkçe",
    nativeLabel: "Turkish",
    icon: <TurkeyFlagIcon fontSize='small' />
  },
  {
    value: "danish",
    label: "🇩🇰 Dansk",
    labelWithoutFlag: "Dansk",
    nativeLabel: "Danish",
    icon: <DenmarkFlag2Icon fontSize='small' />
  },
  {
    value: "hungarian",
    label: "🇭🇺 Magyar",
    labelWithoutFlag: "Magyar",
    nativeLabel: "ungarian",
    icon: <HungaryFlagIcon fontSize='small' />
  },
  {
    value: "english_uk",
    label: "🇬🇧 English (UK)",
    labelWithoutFlag: "English (UK)",
    nativeLabel: "English (UK)",
    icon: <UKFlagIcon fontSize='small' />
  }
].sort((a, b) => {
  if (a.value > b.value) {
    return 1;
  } else {
    return -1;
  }
});

export const DEFAULT_GEN_LANGUAGE = { value: "english", label: "🇺🇸 English" };

export const LANGUAGE_CODE = {
  ms: "malay",
  "zh-TW": "chinese (traditional)",
  "zh-CN": "chinese (simplified)",
  hr: "croatian",
  en: "english",
  nl: "dutch",
  id: "indonesian",
  vi: "vietnamese",
  pt: "portuguese_br",
  fi: "finnish",
  fr: "french",
  de: "german",
  it: "italian",
  no: "norwegian",
  pl: "polish",
  ro: "romanian",
  cs: "czech",
  es: "spanish",
  sv: "swedish",
  tr: "turkish",
  da: "danish",
  hu: "hungarian",
  "en-gb": "english_uk"
};

export const COMP_TOUR_STEPS = [
  {
    target: ".tour-step-0",
    content: "Check your Selected Handle’s Top Metrics here.",
    title: "Welcome!",
    placement: "left",
    disableBeacon: false
  },
  {
    target: ".tour-step-1",
    content: "Change the Date Range to Filter Posts.",
    placement: "auto"
  },
  {
    target: ".tour-step-2",
    content: "This shows the different Content Categories used by this handle.",
    placement: "auto"
  },
  {
    target: ".tour-step-3",
    content: "Check Content Patterns according to each Post type.",
    placement: "auto"
  },
  {
    target: ".tour-step-4",
    content: "Click here to explore different Content themes published by the handle.",
    placement: "auto",
    spotlightClicks: true
  },
  {
    target: ".tour-step-5",
    content:
      "Shows Different Content Themes used by the handle and the engagement of each group. Hint - Sort to See the best performing theme!",
    placement: "auto"
  },
  {
    target: ".tour-step-6",
    content: "Click here to explore different Hashtag analysis published by the handle.",
    placement: "auto",
    spotlightClicks: true
  },
  {
    target: ".tour-step-7",
    content: "Check the Most used Hashtag Sets detected by Predis.ai",
    placement: "auto"
  },
  {
    target: ".tour-step-8",
    content: "Click here to explore this handle's Post Analytics.",
    placement: "auto",
    spotlightClicks: true
  },
  {
    target: ".tour-step-9",
    content: "Check the different post types published by the handle.",
    placement: "auto"
  },
  {
    target: ".tour-step-10",
    content: "Check the Engagement Activity of published posts here.",
    placement: "auto"
  },
  {
    target: ".tour-step-11",
    content: "Here are the top 5 best performing posts of the handle.",
    placement: "auto",
    scrollToFirstStep: true
  }
];
export const LIB_TOUR_STEPS = [
  {
    target: ".lib-tour-0",
    content: "A Quick Tour! Click On The Card to Edit the Post.",
    title: "Welcome!",
    placement: "right",
    disableBeacon: false
  },
  {
    target: ".lib-tour-1",
    content: "Review and Schedule / Publish to multiple platforms.",
    placement: "top"
  },
  {
    target: ".lib-tour-2",
    content: "Create Single Image, Carousel or Video posts from here.",
    placement: "auto"
  },
  {
    target: ".lib-tour-3",
    content: "Let us know if you like or don't like the generated post.",
    placement: "auto",
    scrollToFirstStep: true
  }
];

export const PATH_QUERY = new URLSearchParams(window.location.search);

export const EDIT_POST_TOUR_STEPS = [
  {
    target: ".edit-tour-step-0",
    content: "This is the Creative Editor",
    placement: "auto",
    disableBeacon: true
  },
  {
    target: ".edit-tour-step-1",
    content: "Use these to edit the creative.",
    placement: "auto"
  },
  {
    target: ".edit-tour-step-2",
    content: "Generate more Captions here.",
    placement: "auto"
  },
  {
    target: ".edit-tour-step-3",
    content: "Select hashtags from here.",
    placement: "auto"
  },
  {
    target: ".edit-tour-step-4",
    content: "Get AI-Suggestions to improve your post.",
    placement: "auto"
  },
  {
    target: ".edit-tour-step-5",
    content: "Go back to the Library from here.",
    placement: "auto"
  }
];

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAzX-jeeVITX7BFDWyjxmqNGT-SU0zLwyg",
  authDomain: "fiery-nimbus-261705.firebaseapp.com",
  databaseURL: "https://fiery-nimbus-261705-default-rtdb.firebaseio.com",
  projectId: "fiery-nimbus-261705",
  storageBucket: "fiery-nimbus-261705.appspot.com",
  messagingSenderId: "295142708",
  appId: "1:295142708:web:a2303b0f515d04fdb35161",
  measurementId: "G-4ESYT0F1HQ"
};

export const FREE_PLAN_IDS = ["forever_free", "cbdemo_trial"];
export const FOREVER_FREE_ID = "forever_free";
export const DEFAULT_ONBOARDING_PALETTE = ["#FFFFFF", "#0C69C3", "#B7D0E8"];
export const DEFAULT_WORKSPACE_PALETTE = ["#FFFFFF", "#0C69C3", "#B7D0E8", "#F3D60B", "#EFDF98"];
export const bannerHeight = "30px";
export const bannerHeightXS = "60px";
export const uploadAssetIndividualSize = 500; // upload asset max size single file

export const uploadAssetTotalSize = 500; // upload asset max size multiple files

export const LOGIN_PLATFORM_SEMRUSH = "semrush_appstore";
export const SEMRUSH_RESTRICTED_ECOM_PLATFORMS = [
  "shopify",
  "wix",
  "woocommerce",
  "squarespace",
  "ecwid"
];
export const SEMRUSH_HOST = process.env.REACT_APP_SEMRUSH_HOST;
export const FB_OAUTH_RATE_LIMIT = 10;

export const POST_COUNTER_MAX_POSTS = 3;
export const N_POST_RUNNING_WARNING = 30;
export const USER_AGENT = navigator.userAgent;

export const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
//eslint-disable-next-line
export const LINK_REGEX = /^(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,6}(\/\S*)?$/gm;
export const HASHTAG_REGEX = /([#＃]+)([0-9A-Z_]*[A-Z_]+[a-z0-9_üÀ-ÖØ-öø-ÿ]*)/gi;

export const CHARGEBEE_APP_NAME = process.env.REACT_APP_CHARGEBEE_APP_NAME;

export const PLAT_TO_NAME_MAP = {
  ig: "Instagram",
  fb: "Facebook",
  li: "Linkedin",
  pi: "Pinterest",
  tt: "TikTok",
  twt: "Twitter",
  fbg: "Facebook Groups",
  gmb: "Google My Business",
  yt: "Youtube",
  igs: "Instagram Stories"
};

export const MOBILE_BREAKPOINT = 900;
export const MOBILE_BREAKPOINT_XS = 600;

export const FILE_TYPES_MAP = {
  "image/jpg": "jpg",
  "image/jpeg": "jpeg",
  "image/png": "png",
  "image/svg+xml": "svg",
  "video/mp4": "mp4",
  "audio/mpeg": "mp3",
  "image/vnd.adobe.photoshop": "psd",
  "": "psd",
  "application/pdf": "pdf",
  "image/webp": "webp"
};

export const SCRIPTS_MANAGER_TOTAL_SLIDES_LIMIT = 10;
// Scripts Slides = Total - Title and CTA = total-2
export const SCRIPTS_SLIDES_COUNT = 8;

export const SINGLE_POST_WAIT_TIME = 45;

export const MOMENT_DISPLAY_DATE_FORMAT = "MMM do ''yy"; //"MMM do ' yyy";

export const MOMENT_DISPLAY_DATE_TIME_FORMAT = "MMMM do yyyy, hh:mm:ss a";
export const MOMENT_DISPLAY_DATE_TIME_FORMAT_SHORT = "MMM do ''yy, hh:mm a";
// Refer Expo Linking Docs for Prod URL to be changed at Prod time
// export const APP_LAUNCH_URL = "exp://u.expo.dev/18b94432-653c-4061-90b3-ee290aec39b6/?runtime-version=1.0.8/--/";
export const APP_LAUNCH_URL = "predis:///--/";
// export const APP_LAUNCH_URL = "exp://192.168.0.100:19000/--/"
// Update Sentry Release Version from here
export const SENTRY_RELEASE_VERSION = process.env.REACT_APP_BUILD_VERSION;

export const AGENCY_MONTHLY_PLAN_ID = "agency_23";
export const AGENCY_YEARLY_PLAN_ID = "agency_23_ann";

export const COUNTRY_CODES_TO_NAME = {
  FR: "France",
  CA: "Canada",
  IN: "India",
  US: "United States",
  ES: "Spain"
};

// eslint-disable-next-line
//sorted on the basis of the value
export const APP_LANGUAGES = {
  all: {
    icon: <WorldFlagIcon />,
    label: "All",
    enabled: false,
    labelwithIcon: "All",
    value: "all"
  },
  "zh-CN": {
    icon: <ChineseFlagIcon fontSize='small' />,
    label: "简体中文",
    enabled: true,
    labelwithIcon: "🇨🇳 简体中文",
    nativeLabel: "Chinese - Simplified",
    value: "chinese (simplified)"
  },
  "zh-TW": {
    icon: <ChineseFlagIcon fontSize='small' />,
    label: "中文",
    enabled: true,
    labelwithIcon: "🇨🇳 中文",
    nativeLabel: "Chinese - Traditional",
    value: "chinese (traditional)"
  },
  hr: {
    icon: <CrotiaFlagIcon fontSize='small' />,
    label: "Hrvatski",
    enabled: true,
    labelwithIcon: "🇭🇷 Hrvatski",
    nativeLabel: "Croatian",
    value: "croatian"
  },
  cs: {
    icon: <CzechFlagIcon fontSize='small' />,
    label: "Čeština",
    enabled: true,
    labelwithIcon: "🇨🇿 Čeština",
    nativeLabel: "Czech",
    value: "czech"
  },
  da: {
    icon: <DenmarkFlag2Icon fontSize='small' />,
    label: "Dansk",
    enabled: true,
    labelwithIcon: "🇩🇰 Dansk",
    nativeLabel: "Danish",
    value: "danish"
  },
  nl: {
    icon: <NetherlandFlagIcon fontSize='small' />,
    label: "Nederlands",
    enabled: true,
    labelwithIcon: "🇳🇱 Nederlands",
    nativeLabel: "Dutch",
    value: "dutch"
  },
  en: {
    icon: <USFlagIcon fontSize='small' />,
    label: "English (US)",
    enabled: true,
    labelwithIcon: "🇺🇸 English (US)",
    nativeLabel: "English",
    value: "english"
  },
  "en-gb": {
    icon: <UKFlagIcon fontSize='small' />,
    label: "English (UK)",
    enabled: true,
    labelwithIcon: "🇬🇧 English (UK)",
    nativeLabel: "English (UK)",
    value: "english_uk"
  },
  fi: {
    icon: <FinlandFlagIcon fontSize='small' />,
    label: "Suomi",
    enabled: true,
    labelwithIcon: "🇫🇮 Suomi",
    nativeLabel: "Finnish",
    value: "finnish"
  },
  fr: {
    icon: <FrenchFlagIcon fontSize='small' />,
    label: "Français",
    enabled: true,
    labelwithIcon: "🇫🇷 Français",
    nativeLabel: "French",
    value: "french"
  },
  de: {
    icon: <DenmarkFlagIcon fontSize='small' />,
    label: "Deutsch",
    enabled: true,
    labelwithIcon: "🇩🇪 Deutsch",
    nativeLabel: "German",
    value: "german"
  },
  hu: {
    icon: <HungaryFlagIcon fontSize='small' />,
    label: "Magyar",
    enabled: true,
    labelwithIcon: "🇭🇺 Magyar",
    nativeLabel: "ungarian",
    value: "hungarian"
  },
  id: {
    icon: <IndonesiaFlagIcon fontSize='small' />,
    label: "Bahasa Indonesia",
    enabled: true,
    labelwithIcon: "🇮🇩 Bahasa Indonesia",
    nativeLabel: "Indonesian",
    value: "indonesian"
  },
  it: {
    icon: <ItalyFlagIcon fontSize='small' />,
    label: "Italiano",
    enabled: true,
    labelwithIcon: "🇮🇹 Italiano",
    nativeLabel: "Italian",
    value: "italian"
  },
  ms: {
    icon: <MalaysiaFlagIcon fontSize='small' />,
    label: "Malay",
    enabled: true,
    labelwithIcon: "🇲🇾 Malay",
    nativeLabel: "Bahasa Melayu",
    value: "malay"
  },
  no: {
    icon: <NorwayFlagIcon fontSize='small' />,
    label: "Norsk",
    enabled: true,
    labelwithIcon: "🇳🇴 Norsk",
    nativeLabel: "Norwegian",
    value: "norwegian"
  },
  pl: {
    icon: <PolandFlagIcon fontSize='small' />,
    label: "Polski",
    enabled: true,
    labelwithIcon: "🇵🇱 Polski",
    nativeLabel: "Polish",
    value: "polish"
  },
  pt: {
    icon: <PortugeseFlagIcon fontSize='small' />,
    label: "Português",
    enabled: true,
    labelwithIcon: "🇧🇷 Português",
    nativeLabel: " Portuguese BR",
    value: "portuguese_br"
  },
  ro: {
    icon: <RomaniaFlagIcon fontSize='small' />,
    label: "Română",
    enabled: true,
    labelwithIcon: "🇷🇴 Română",
    nativeLabel: "Romanian",
    value: "romanian"
  },
  es: {
    icon: <SpainFlagIcon fontSize='small' />,
    label: "Español",
    enabled: true,
    labelwithIcon: "🇪🇸 Español",
    nativeLabel: "Spanish",
    value: "spanish"
  },
  sv: {
    icon: <SwedenFlagIcon fontSize='small' />,
    label: "Svenska",
    enabled: true,
    labelwithIcon: "🇸🇪 Svenska",
    nativeLabel: "Swedish",
    value: "swedish"
  },
  tr: {
    icon: <TurkeyFlagIcon fontSize='small' />,
    label: "Türkçe",
    enabled: true,
    labelwithIcon: "🇹🇷 Türkçe",
    nativeLabel: "Turkish",
    value: "turkish"
  },
  vi: {
    icon: <VietnamFlagIcon fontSize='small' />,
    label: "Tiếng Việt",
    enabled: true,
    labelwithIcon: "🇻🇳 Tiếng Việt",
    nativeLabel: "Vietnamese",
    value: "vietnamese"
  }
};

export const DEFAULT_APP_LANGUAGE = "en";
export const POLOTNO_EMPTY_JSON = {
  width: 1080,
  height: 1080,
  fonts: [],
  pages: [
    {
      id: "jCn7QzYZU-",
      children: [],
      width: "auto",
      height: "auto",
      background: "white",
      bleed: 0,
      duration: 5000
    }
  ],
  unit: "px",
  dpi: 72
};
// referral cookies

export const REF_HISTORY_COOKIE_KEY = "pred_history";

export const POST_TYPE_VAL_TO_LABEL_MAP = {
  promotional: "Promotional",
  educational: "Educational",
  behind_the_scenes: "Behind the scenes",
  contest: "Contest",
  poll: "Poll",
  expert_interview: "Expert interview"
};

export const ASSET_PLATFORM_FREEPIK = "freepik";

//Resizing Constants
export const RESIZE_VARIANTS_IMAGE = [
  "square",
  "portrait_1",
  "portrait_2",
  "portrait_12",
  "landscape_1",
  "landscape_2",
  "custom"
];
export const RESIZE_VARIANTS_VIDEO = [
  "portrait_1",
  "portrait_12",
  "square",
  "landscape_1",
  "custom"
];

export const RESIZE_VARIANTS_CAROUSEL = ["square", "custom"];

export const GEN_RESIZE_MAPPINGS = {
  single_image: RESIZE_VARIANTS_IMAGE,
  video: RESIZE_VARIANTS_VIDEO,
  carousel: RESIZE_VARIANTS_CAROUSEL
};

//SDK Resizing Variants
export const SDK_IMAGE_RESIZES = [
  "square",
  "portrait_1",
  "portrait_2",
  "landscape_1",
  "landscape_2",
  "portrait_3",
  "landscape_3",
  "landscape_4",
  "square_2",
  "square_3",
  "portrait_4",
  "portrait_5",
  "portrait_6",
  "landscape_5",
  "landscape_6",
  "landscape_7",
  "landscape_8",
  "landscape_9",
  "landscape_10",
  "landscape_11",
  "landscape_12",
  "square_4",
  "square_5",
  "portrait_7",
  "portrait_8",
  "portrait_9",
  "portrait_10",
  "portrait_11",
  "portrait_12",
  "landscape_13",
  "landscape_14",
  "landscape_15",
  "landscape_16",
  "landscape_17",
  "landscape_18",
  "landscape_19",
  "landscape_20",
  "landscape_21",
  "landscape_22",
  "landscape_23",
  "landscape_24",
  "landscape_25",
  "custom"
];

export const SDK_CAROUSEL_RESIZES = ["square", "custom"];

export const SDK_RESIZE_VARIANTIONS = {
  single_image: SDK_IMAGE_RESIZES,
  video: SDK_IMAGE_RESIZES,
  carousel: SDK_CAROUSEL_RESIZES
};

export const RESIZE_MAPPINGS = {
  embed: SDK_RESIZE_VARIANTIONS,
  notEmbed: GEN_RESIZE_MAPPINGS
};

export const SIZE_RATIO_MAPPING = {
  square: {
    ratio: "1:1",
    width: 1080,
    height: 1080,
    name: "Square"
  },
  portrait_1: {
    ratio: "9:16",
    width: 1080,
    height: 1920,
    name: "Portrait"
  },
  portrait_12: {
    ratio: "4:5",
    width: 1080,
    height: 1350,
    name: "Portrait"
  },
  portrait_2: {
    ratio: "1:5",
    width: 120,
    height: 600,
    name: "Skyscraper"
  },
  portrait_3: {
    ratio: "4:5",
    width: 960,
    height: 1200,
    name: "Portrait"
  },
  landscape_1: {
    ratio: "16:9",
    width: 1280,
    height: 720,
    name: "Landscape"
  },
  landscape_2: {
    ratio: "15:2",
    width: 375,
    height: 50,
    name: "Landscape"
  },
  landscape_3: {
    ratio: "485:33",
    width: 970,
    height: 66,
    name: "Landscape"
  },
  landscape_4: {
    ratio: "600:314",
    width: 1200,
    height: 628,
    name: "Landscape"
  },
  square_2: {
    ratio: "1:1",
    width: 1200,
    height: 1200,
    name: "Square"
  },
  square_3: {
    ratio: "1:1",
    width: 240,
    height: 240,
    name: "Square"
  },
  portrait_4: {
    ratio: "1:5",
    width: 60,
    height: 300,
    name: "Portrait"
  },
  portrait_5: {
    ratio: "32:39",
    width: 768,
    height: 905,
    name: "Portrait"
  },
  portrait_6: {
    ratio: "52:91",
    width: 1040,
    height: 1840,
    name: "Portrait"
  },
  landscape_5: {
    ratio: "16:9",
    width: 1920,
    height: 1080,
    name: "Landscape"
  },
  landscape_6: {
    ratio: "16:5",
    width: 1920,
    height: 550,
    name: "Landscape"
  },
  landscape_7: {
    ratio: "2:1",
    width: 720,
    height: 360,
    name: "Landscape"
  },
  landscape_8: {
    ratio: "16:8",
    width: 640,
    height: 334,
    name: "Landscape"
  },
  landscape_9: {
    ratio: "16:9",
    width: 1920,
    height: 903,
    name: "Landscape"
  },
  landscape_10: {
    ratio: "69:35",
    width: 690,
    height: 350,
    name: "Landscape"
  },
  landscape_11: {
    ratio: "505:270",
    width: 505,
    height: 270,
    name: "Landscape"
  },
  landscape_12: {
    ratio: "221:88",
    width: 884,
    height: 352,
    name: "Landscape"
  },
  square_4: {
    ratio: "1:1",
    width: 200,
    height: 200,
    name: "Square"
  },
  square_5: {
    ratio: "1:1",
    width: 250,
    height: 250,
    name: "Square"
  },
  portrait_7: {
    ratio: "3:5",
    width: 240,
    height: 400,
    name: "Portrait"
  },
  portrait_8: {
    ratio: "25:36",
    width: 250,
    height: 360,
    name: "Portrait"
  },
  portrait_9: {
    ratio: "8:30",
    width: 160,
    height: 600,
    name: "Portrait"
  },
  portrait_10: {
    ratio: "1:2",
    width: 300,
    height: 600,
    name: "Portrait"
  },
  portrait_11: {
    ratio: "1:3.5",
    width: 300,
    height: 1050,
    name: "Portrait"
  },
  landscape_13: {
    ratio: "6:5",
    width: 300,
    height: 250,
    name: "Landscape"
  },
  landscape_14: {
    ratio: "84:70",
    width: 336,
    height: 280,
    name: "Landscape"
  },
  landscape_15: {
    ratio: "29:20",
    width: 580,
    height: 400,
    name: "Landscape"
  },
  landscape_16: {
    ratio: "39:5",
    width: 468,
    height: 60,
    name: "Landscape"
  },
  landscape_17: {
    ratio: "81:10",
    width: 728,
    height: 90,
    name: "Landscape"
  },
  landscape_18: {
    ratio: "155:30",
    width: 930,
    height: 180,
    name: "Landscape"
  },
  landscape_19: {
    ratio: "107:10",
    width: 970,
    height: 90,
    name: "Landscape"
  },
  landscape_20: {
    ratio: "39:10",
    width: 970,
    height: 250,
    name: "Landscape"
  },
  landscape_21: {
    ratio: "49:6",
    width: 980,
    height: 120,
    name: "Landscape"
  },
  landscape_22: {
    ratio: "6:1",
    width: 300,
    height: 50,
    name: "Landscape"
  },
  landscape_23: {
    ratio: "32:5",
    width: 320,
    height: 50,
    name: "Landscape"
  },
  landscape_24: {
    ratio: "32:10",
    width: 320,
    height: 100,
    name: "Landscape"
  },
  landscape_25: {
    ratio: "5:1",
    width: 300,
    height: 60,
    name: "Landscape"
  }
};

export const getIconVariant = (width: any, height: any) => {
  if (width === height) {
    return "square";
  } else if (width > height) {
    return "landscape";
  } else {
    return "portrait";
  }
};

export const ASPECT_ICON_MAPPINGS = {
  square: {
    icon: <CropSquareOutlinedIcon />,
    gradientIcon: <CropSquareOutlinedIcon color='primary' />
  },
  portrait: {
    icon: <CropPortraitOutlinedIcon />,
    gradientIcon: <CropPortraitOutlinedIcon color='primary' />
  },
  landscape: {
    icon: <Crop32OutlinedIcon />,
    gradientIcon: <Crop32OutlinedIcon color='primary' />
  }
};

export const BASE_ASPECT_VARIANT = {
  single_image: "square",
  video: "portrait_1",
  carousel: "square"
};
